import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Component, ComponentRef, Inject, Injector, Input, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { IAppSettings } from 'shared/environment';
import { IBaseEdit, IDraw, IDrawFinish, IEdit, ILayer, IPluginInterface } from 'shared/interfaces';
import { DictionaryService } from 'shared/services/dictionary.service';
import { LayersStore } from 'shared/stores/LayersStore';
import {Attribute, Feature, Image, PluginClass, Point, Utils} from '../../classes';
import { Creator, StateService } from '../../services';
import { CrowdsourceFormComponent } from './crowdsource-form.component';

@Component({
  selector: 'crowdsourcing',
  templateUrl: 'crowdsourcing.component.html',
  styleUrls: ['crowdsourcing.component.less']
})
export class MobileCrowdsourcingComponent extends PluginClass implements IDrawFinish, IDraw, IBaseEdit, OnInit {
  @Input() pictureFormats:string[] = ['png', 'jpeg', 'gif', 'jpg'];
  @Input() maxSize = 10485760; // = 10 Mb

  layer:ILayer;
  feature:Feature;
  attributes:Attribute[];

  private drawPlugin:IDraw;
  private editPlugin:IEdit;
  private projectSlug:string;
  private formCompRef:ComponentRef<CrowdsourceFormComponent>;
  private commitSub:Subscription;

  constructor(
    private httpClient:HttpClient,
    public dictService:DictionaryService,
    public layersStore:LayersStore,
    private creator:Creator,
    private appRef:ApplicationRef,
    private injector:Injector,
    private stateService:StateService,
    @Inject('environment') settings:IAppSettings
  ) {
    super();

    this.projectSlug = settings.PROJECT_SLUG;

    layersStore
      .onOptionsLoaded()
      .pipe(
        switchMap(() => layersStore.getActiveLayers()),
        map(layers => layers.find(item => item.crowdsource && ['point', 'marker', 'multipoint'].indexOf(item.geomType) !== -1)),
        take(1)
      )
      .subscribe(layer => {
        if (!layer) {
          return;
        }
        this.layer = layer;
        this.attributes = this.layer.columns
          .filter((column:Attribute) => !column.is_pk && column.crowdsourceSettings && !column.crowdsourceSettings.hidden)
          .sort((c1:Attribute, c2:Attribute) => c1.crowdsourceSettings.order - c2.crowdsourceSettings.order);
      });
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Draw':
        this.drawPlugin = pi as IDraw;
        break;
      case 'Edit':
        this.editPlugin = pi as IEdit;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Draw':
        this.drawPlugin = null;
        break;
      case 'Edit':
        this.editPlugin = null;
        break;
    }
  }

  startDraw() {
    // сообщает карте, что необходимо скрыть инструменты
    this.stateService.crowdsourceMode$.next(true);
    // краудсорсинг пока что работает только для точечной геометрии
    this.drawPlugin.startDraw('point', this as IDrawFinish);

    // при отмене в режиме редактирования геометрии, очищаем карту
    this.stateService.crowdsourceMode$.pipe(take(1)).subscribe(value => {
      if (!value) {
        this.feature = null;
        this.clearEditedFeatures();
        if (this.commitSub) {
          this.commitSub.unsubscribe();
          this.commitSub = null;
        }
      }
    });
  }

  finishDraw(feature:Feature) {
    this.feature = feature;
    this.stopDraw();
    this.clearDrawFeatures();
    // для отображения геометрии в режиме редактирования
    // переводим координаты в формат lat, lng
    feature.invertCoordinates();
    this.showEditableFeatures([feature]);
    feature.invertCoordinates();
    this.stateService.crowdsourceFeature$.next(feature);

    this.commitSub = this.stateService.commitCrowdsourceFeature$.pipe(take(1)).subscribe(() => {
      // подставляем значения по умолчанию из настроек краудсорсинга
      this.layer.columns.forEach((column:Attribute) => {
        this.feature.properties[column.name] = column.crowdsourceSettings ? column.crowdsourceSettings.defaultValue : null;
      });

      // создание компонента с формой в корневом viewContainerRef
      const classOfRootComponent = this.appRef.componentTypes[0];
      const appInstance = this.injector.get(classOfRootComponent);
      const vcRef = appInstance.viewContainerRef;
      this.creator.createComponent(CrowdsourceFormComponent, vcRef).then((compRef:ComponentRef<CrowdsourceFormComponent>) => {
        this.formCompRef = compRef;
        const instance = compRef.instance;
        instance.feature = this.feature;
        instance.attributes = this.attributes;

        instance.cancel.pipe(take(1)).subscribe(() => {
          this.stopCrowdsource();
        });

        instance.submit.pipe(take(1)).subscribe(() => {
          this.saveFeature();
        });
      });
    });
  }

  stopDraw() {
    this.drawPlugin.stopDraw();
  }

  addFeature() {}

  addGeometry(points:Point[], type:string, style?:any) {}

  showEditableFeatures(features:Feature[]) {
    this.editPlugin.showEditableFeatures(features);
  }

  clearEditedFeatures() {
    this.editPlugin.clearEditedFeatures();
  }

  removeEditedFeaturesByType(type:String):void {
    throw new Error('Not imlpemented');
  }

  updateEditableFeatures(geometry:GeoJSON.DirectGeometryObject) {
    this.feature.geometry = geometry;
  }

  highlightVertex() {}

  startSnapping() {}

  stopSnapping() {}

  clearDrawFeatures() {
    this.drawPlugin.clearDrawFeatures();
  }

  updateDrawingFeature(feature:Feature) {}

  saveFeature() {
    // Обнулить пустые свойства (из инпутов возвращаются пустые строки, на которые ругается БД)
    Object.keys(this.feature.properties).forEach((key:string) => {
      if (this.feature.properties[key] === '' || this.feature.properties[key] == null) {
        this.feature.properties[key] = null;
      }
    });

    return this.httpClient
      .post<any>('geom/create_or_update', {
        layer_id: this.layer.id,
        geom: Utils.geojsonToWKT(this.feature.geometry),
        attributes: this.feature.properties
      })
      .pipe(
        map(result => result.pk),
        switchMap(pk => (this.feature.images.length ? this.saveImages(pk) : of(pk)))
      )
      .subscribe(() => {
        this.stopCrowdsource();
        this.layer.refresh();
      });
  }

  private stopCrowdsource() {
    this.formCompRef.destroy();
    this.formCompRef = null;
    this.feature = null;
    this.stateService.crowdsourceFeature$.next(null);
    this.stateService.crowdsourceMode$.next(false);
  }

  private saveImages(pk:number):Observable<any> {
    const data:any = {
      object_id: pk,
      layer: this.layer.id,
      type: 'image',
      project_name: this.projectSlug
    };

    this.feature.images.forEach((image:Image, i:number) => {
      data[`file${i}`] = image.file;
    });

    const formdata = new FormData();
    Object.keys(data).forEach(key => {
      formdata.append(key, data[key]);
    });

    return this.httpClient.post('upload_list/', formdata);
  }
}
