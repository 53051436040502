import { Component, ElementRef, Inject } from '@angular/core';
import { IUser } from 'shared';
import { IAppSettings } from 'shared/environment';
import { AuthService, Creator } from '../../services/';
import { SlidePanelComponent } from '../shared';

@Component({
  selector: 'settings-panel',
  templateUrl: 'settings-panel.component.html',
  styleUrls: ['settings-panel.component.less']
})
export class SettingsPanelComponent extends SlidePanelComponent {
  projectName:string;
  projectImage:string;
  user:IUser = { is_anonymous: true, name: null, can_edit: false };

  constructor(
    creator:Creator,
    _auth:AuthService,
    el:ElementRef,
    @Inject('environment') settings:IAppSettings
  ) {
    super(creator, el);
    this.projectName = settings.PROJECT_NAME;
    this.projectImage = settings.PROJECT_IMAGE;
    _auth.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  closePanel() {
    this.btn.setActive(false);
  }

  location(url:string) {
    window.location.href = url;
  }
}
