import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BaseModule } from 'shared/base.module';
import { CoreModule } from 'shared/core.module';
import { AppComponent } from './app.component';
import {
  CrowdsourceFormComponent,
  FileUploaderComponent,
  LayerTypeIconWidget,
  LocationComponent,
  MobileBaseMapsComponent,
  MobileCrowdsourcingComponent,
  MobileLayerCatalogComponent,
  MobileLayerManagerComponent,
  MobileLayerTreeComponent,
  MobileLegend,
  MobilePage,
  SearchResultsComponent,
  SettingsPanelComponent,
  TextSearchComponent
} from './components';
import { SwipeListener } from './directives';
import { StateService } from './services';

export class MyHammerConfig extends HammerGestureConfig {
  overrides:{
    swipe:{
      velocity:0.2;
      threshold:5;
      directions:2 | 4 | 8 | 16;
    };
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SearchResultsComponent,
    MobilePage,
    LocationComponent,
    MobileLayerManagerComponent,
    SwipeListener,
    SettingsPanelComponent,
    TextSearchComponent,
    LayerTypeIconWidget,
    MobileBaseMapsComponent,
    MobileLayerCatalogComponent,
    MobileLayerTreeComponent,
    MobileLegend,
    MobileCrowdsourcingComponent,
    CrowdsourceFormComponent,
    FileUploaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BaseModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken'
    }),
    CoreModule.forRoot()
  ],
  entryComponents: [
    SearchResultsComponent,
    MobilePage,
    LocationComponent,
    MobileLayerManagerComponent,
    SettingsPanelComponent,
    MobileBaseMapsComponent,
    TextSearchComponent,
    MobileLayerCatalogComponent,
    MobileLegend,
    MobileCrowdsourcingComponent,
    CrowdsourceFormComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    StateService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ]
})
export class AppModule {}
