import { Component } from '@angular/core';
import { ILayer } from 'shared/interfaces';
import { LayersStore } from '../../services';
import { LayerManagerComponent } from '../shared';

interface StateItem {
  showOrder:boolean;
  showDel:boolean;
  showDelControls:boolean;
  showOrderControls:boolean;
}

@Component({
  selector: 'mobile-layer-manager',
  templateUrl: 'layer-manager.component.html',
  styleUrls: ['layer-manager.component.less']
})
export class MobileLayerManagerComponent extends LayerManagerComponent {
  active = true;

  state:{ [key:number]:StateItem };

  constructor(protected layersStore:LayersStore) {
    super(layersStore);
  }

  swipeLeft(layer:ILayer) {
    if (this.state[layer.id].showOrder) {
      this.state[layer.id].showOrder = false;
      // ждем окончания transition, иначе кнопки исчезают раньше, чем сдвинется контейнер
      setTimeout(() => {
        this.state[layer.id].showOrderControls = false;
      }, 300);
    } else {
      this.state[layer.id].showDel = true;
      this.state[layer.id].showDelControls = true;
    }
  }

  swipeRight(layer:ILayer) {
    if (this.state[layer.id].showDel) {
      this.state[layer.id].showDel = false;
      // ждем окончания transition, иначе кнопки исчезают раньше, чем сдвинется контейнер
      setTimeout(() => {
        this.state[layer.id].showDelControls = false;
      }, 300);
    } else {
      this.state[layer.id].showOrder = true;
      this.state[layer.id].showOrderControls = true;
    }
  }

  toggleLayer(layer:ILayer) {
    layer.visible = !layer.visible;
    this.layersStore.updateLayer(layer);
  }

  removeLayer(layer:ILayer) {
    layer.visible = false;
    this.layersStore.updateLayer(layer);
  }

  changeOrder(up:boolean, layer:ILayer) {
    const oldOrder = layer.order;
    layer.order = up ? oldOrder + 1 : oldOrder - 1;
    const idx = this.activeLayers.findIndex(item => item.id === layer.id);
    const neighbourLayerIdx = up ? idx - 1 : idx + 1;
    this.activeLayers[neighbourLayerIdx].order = oldOrder;
    this.sortLayers();
  }

  protected setLayers() {
    this.layersStore.getActiveLayers().subscribe(data => {
      this.activeLayers = data;
      this.sortLayers();
      this.state = this.activeLayers.reduce((acc, layer) => {
        acc[layer.id] = {
          showOrder: false,
          showDel: false,
          showOrderControls: false,
          showDelControls: false
        } as StateItem;
        return acc;
      }, {});
    });
  }

  private sortLayers() {
    this.activeLayers.sort((a:ILayer, b:ILayer) => b.order - a.order);
  }
}
