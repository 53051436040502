import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Environment } from './environment';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { UrlInterceptor } from './interceptors/url.interceptor';
import {
  AuthService,
  ConnectionService,
  Creator,
  DictionaryService,
  LayersFactory,
  LayersService,
  MapService
} from './services';
import { LayersStore } from './stores/LayersStore';

@NgModule({})
export class CoreModule {
  static forRoot():ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        ConnectionService,
        Creator,
        DictionaryService,
        LayersService,
        MapService,
        LayersFactory,
        AuthService,
        LayersStore,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
        { provide: 'environment', useValue: Environment }
      ]
    };
  }
}
