import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { IAppSettings } from 'shared/environment';
import { IContainer, IContentChild, IPlugin } from 'shared/interfaces';
import { Feature, PluginConnect, Utils } from './classes';
import { ConnectionService, StateService } from './services';

@Component({
  selector: 'my-app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.less']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('mainDiv', { read: ViewContainerRef }) mainDiv:ViewContainerRef;

  resultFeature:Feature;
  crowdsourceMode = false;

  private plugins_connects = new Map<string, PluginConnect[]>();
  private appSettings:IAppSettings;

  constructor(
    private componentFactoryResolver:ComponentFactoryResolver,
    private connectionService:ConnectionService,
    private http:HttpClient,
    private viewContainerRef:ViewContainerRef,
    public stateService:StateService,
    @Inject('environment') settings:IAppSettings
  ) {
    this.viewContainerRef = viewContainerRef;
    this.appSettings = settings;
  }

  ngAfterViewInit() {
    this.createComponents().then(() => {
      this.connectionService.setConnects(this.plugins_connects);
    });

    this.stateService.resultFeature$.subscribe(feature => {
      this.resultFeature = feature;
      this.toggleMapTools(!!feature);
    });

    this.stateService.crowdsourceMode$.subscribe(value => {
      this.crowdsourceMode = value;
      this.toggleMapTools(value);
    });
  }

  // костыль для временного скрытия инструментов карты
  private toggleMapTools(value:boolean) {
    const zIndex = value ? '1004' : 'unset';
    document.getElementById('geoanalitika-map').style.zIndex = zIndex;
  }

  clearFeature() {
    this.stateService.resultFeature$.next(null);
  }

  cancelDraw() {
    this.stateService.crowdsourceMode$.next(false);
    this.stateService.crowdsourceFeature$.next(null);
  }

  commitFeature() {
    this.stateService.commitCrowdsourceFeature$.next();
  }

  private getComponents():Observable<any> {
    return this.http.get(this.appSettings.PLUGINS_URL + location.search);
  }

  private createComponents():Promise<any> {
    return new Promise(resolve => {
      this.getComponents().subscribe((data:any) => {
        const plugins:any[] = data.plugins;
        if (data.environment) {
          this.appSettings.PROJECT_NAME = data.environment.project.name;
          this.appSettings.PROJECT_IMAGE = data.environment.project.image;
        }
        let count = 0;
        plugins.forEach(object => {
          this.create(object.component, object.options).then(com => {
            count++;
            if (count === plugins.length) {
              resolve('allCreated');
            }
          });
        });
      });
    });
  }

  private create(componentName:string, options:any, container?:ViewContainerRef):Promise<any> {
    let retPromise:Promise<any> = null;

    if (container) {
      retPromise = this.createInstance(componentName, options, container);
    } else {
      if (!options.domId) {
        console.error('Не указан элемент, где разместить компонент ' + componentName);
      } else if (!this[options.domId]) {
        console.error('В шаблоне нет элемента ' + options.domId + ', для размещения компонента ' + componentName);
      } else {
        container = this[options.domId] as ViewContainerRef;
        retPromise = this.createInstance(componentName, options, container);
      }
    }

    if (!retPromise) {
      console.log(`Creating ${componentName} error`);
    }
    return retPromise;
  }

  private createInstance(componentName:string, options:any, container?:ViewContainerRef):Promise<any> {
    return new Promise(resolve => {
      try {
        const factories:Type<any>[] = Array.from(this.componentFactoryResolver['_factories'].keys());
        const factoryClass:Type<any> = factories.find((x:any) => Utils.getFuncName(x) === componentName);
        const compFactory:ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(factoryClass);

        if (!compFactory) {
          console.error(`No factory found for ${componentName}`);
          resolve(null);
          return;
        }
        let com:ComponentRef<any>;

        if (process.env.NODE_ENV !== 'production') {
          console.log(`creating component ${componentName}...`);
        }

        if (container) {
          com = container.createComponent(compFactory);
          // параметры, которые не являются св-вами компонента
          const notOpt = ['componentId', 'include', 'domOpt', 'domId', 'connects'];
          for (const opt in options) {
            if (notOpt.indexOf(opt) !== -1) {
              continue;
            }
            com.instance[opt] = options[opt];
          }
          // если заданы настройки контейнера компонента
          const domOpt:any = options['domOpt'];
          const domNode:any = com.instance.el;

          if (domNode && domOpt) {
            for (const opt in domOpt) {
              domNode.nativeElement[opt] = domOpt[opt];
            }
          }

          const componentId = options.componentId || componentName;

          // добавляем компонент в массив компонентов
          this.connectionService.addPlugin(componentId, com.instance);

          // добавляем связи
          this.setConnects(componentId, options.connects);

          let onInitFunc:any;
          // Если нужно создать элементы внутри данного компонента
          if (options.include && options.include.length) {
            this.createChildren(com, options.include, componentId).then((parent:any) => {
              // после загрузки всех копнонетов запускаем ngOnInit
              parent.instance.ngOnInit.call(parent.instance);
              resolve(parent);
            });
          } else {
            // Подменяем функцию ngOnInit
            // для определения, что компонент инициализирован
            onInitFunc = com.instance.ngOnInit;

            if (!onInitFunc) {
              console.error(`Component ${componentName} must extend PluginClass or implement OnInit interface!`);
              resolve(null);
            }

            com.instance.ngOnInit = () => {
              onInitFunc.call(com.instance);
              resolve(com);
            };
          }

          // Проверка на браузеры
          if (com.instance.checkBrowserSupport) {
            com.instance.checkBrowserSupport();
          }
        }
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(componentName, e);
        }
        resolve(null);
      }
    });
  }

  private createChildren(parent:ComponentRef<any>, children:any[], componentId:string):Promise<any> {
    const self = this;
    const length = children.length;
    return new Promise(resolve => {
      children.forEach((child:any, idx:number) => {
        const options = child.options;
        self.create(child.component, options, (parent.instance as IContainer).getContainer()).then((childCom:ComponentRef<any>) => {
          try {
            // Сохраняем ссылки на детей в родительском компоненте
            (parent.instance as IContainer).addChild(childCom.instance as IContentChild);
            (childCom.instance as IContentChild).parentComponent = parent.instance as IContainer;

            // функция на загрузку компонента
            (childCom.instance as IPlugin).onLoad();
            //
            if (idx === length - 1) {
              resolve(parent);
            }
          } catch (e) {
            if (process.env.NODE_ENV !== 'production') {
              console.error(child.component, e);
            }
            resolve(parent);
          }
        });
      });
    });
  }

  private setConnects(componentName:string, connects:any[]) {
    const ar:PluginConnect[] = [];
    if (!connects) {
      connects = [];
    }
    connects.forEach(connect => {
      const name = connect.componentId || connect.component;
      ar.push(new PluginConnect(name, connect.connectName, connect.interface));
    });
    this.plugins_connects.set(componentName, ar);
  }
}
