import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feature, Image } from '../classes';

@Injectable()
export class StateService {
  resultFeature$:Subject<Feature> = new Subject();

  crowdsourceMode$:Subject<boolean> = new Subject();
  crowdsourceFeature$:BehaviorSubject<Feature> = new BehaviorSubject(null);
  commitCrowdsourceFeature$:Subject<void> = new Subject();

  constructor(private http:HttpClient) {}

  getImages(feature:Feature):Observable<Image[]> {
    const pk = feature.properties[feature.layer.pk];
    const params:HttpParams = new HttpParams().set('pk', pk).set('layer_id', `${feature.layer.id}`);
    return this.http.get<any>('attachments/', { params }).pipe(map(data => data.images.map(item => new Image(item))));
  }
}
