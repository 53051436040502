import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'file-uploader',
  templateUrl: 'file-uploader.component.html',
  styleUrls: ['file-uploader.component.less']
})
export class FileUploaderComponent {
  @Input() allowedFormats:string[] = []; // массив MIME-типов и/или допустимых расширений файлов
  @Input() maxSize:number;
  @Input() name:string;

  @Output() onAdd:EventEmitter<File[]> = new EventEmitter<File[]>();

  handleUpload(files:FileList) {
    const validFiles = this.filterUpload(files);
    if (validFiles.length) {
      this.onAdd.emit(validFiles);
    }
  }

  private invalidFormat(file:File):boolean {
    if (!this.allowedFormats.length) {
      return false;
    }
    const mimeIndex = this.allowedFormats.indexOf(file.type);
    const extensionsIndex = this.allowedFormats.indexOf(
      (file.name as any)
        .split('.')
        .pop()
        .toLowerCase()
    );
    return mimeIndex === -1 && extensionsIndex === -1;
  }

  private invalidSize(file:File):boolean {
    if (!this.maxSize) {
      return false;
    }
    return file.size > this.maxSize;
  }

  private filterUpload(files:FileList):File[] {
    return Array.prototype.filter.call(files, (file:File) => !this.invalidFormat(file) && !this.invalidSize(file));
  }
}
