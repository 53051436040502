import { Component, ElementRef, Input, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { IMenu, IPluginInterface } from 'shared/interfaces';
import { Creator } from '../../services';
import { Button, MenuComponent } from '../shared';

@Component({
  selector: 'mobile-page',
  templateUrl: 'mobile-page.component.html',
  styleUrls: ['mobile-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MobilePage extends MenuComponent {
  @Input() caption:string;

  @ViewChild('here', { read: ViewContainerRef }) pageContent:ViewContainerRef;

  visible = false;

  private menu:IMenu;
  private btn:Button;

  constructor(protected creator:Creator, protected el:ElementRef) {
    super(creator, el);
  }

  get btnActive() {
    return this.btns.some(btn => btn.active);
  }

  addInterface(name:string, pi:IPluginInterface) {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;

        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.buttonConfig.onClickMe = () => {
            this.visible = true;
          };
          this.buttonConfig.onDeactivate = () => {
            this.visible = false;
          };
          this.btn.setOptions(this.buttonConfig);
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string) {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  open() {
    this.visible = true;
    this.onShow.emit({});
  }

  close() {
    this.visible = false;
    this.btns.forEach(btn => btn.setActive(false));
    this.onHide.emit({});
  }

  isOpen() {
    return this.visible;
  }

  getContainer() {
    return this.pageContent;
  }
}
