import {ChangeDetectorRef, Component} from '@angular/core';
import { LayersStore } from 'shared/stores/LayersStore';
import { MapService } from '../../services';
import { MapLegend } from '../shared';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'mobile-legend',
  templateUrl: 'legend.component.html',
  styleUrls: ['legend.component.less']
})
export class MobileLegend extends MapLegend {
  constructor(layersStore:LayersStore, mapService:MapService, sanitizer:DomSanitizer, changeDetector:ChangeDetectorRef) {
    super(layersStore, mapService, sanitizer, changeDetector);
  }
}
