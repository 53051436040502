import { Component } from '@angular/core';
import { LayersStore } from 'shared/stores/LayersStore';
import { LayerCatalogComponent } from '../shared';

@Component({
  selector: 'mobile-layer-catalog',
  templateUrl: 'layer-catalog.component.html',
  styleUrls: ['layer-catalog.component.less']
})
export class MobileLayerCatalogComponent extends LayerCatalogComponent {
  constructor(layersStore:LayersStore) {
    super(layersStore);
  }
}
