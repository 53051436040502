import { Component } from '@angular/core';
import { LayersStore, MapService } from '../../services';
import { BaseMapsComponent } from '../shared';

@Component({
  selector: 'basemaps',
  templateUrl: 'basemaps.component.html',
  styleUrls: ['basemaps.component.less']
})
export class MobileBaseMapsComponent extends BaseMapsComponent {
  constructor(protected mapService:MapService, protected layersStore:LayersStore) {
    super(mapService, layersStore);
    this.mapService.mapClick$.subscribe(() => this.toggle());
  }

  toggle() {
    this.btn.setActive(false);
  }
}
