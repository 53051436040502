import { Component, Input } from '@angular/core';

// TODO: подумать, как реализовать @geoanalitika/widgets как набор
// независимых модулей, чтобы можно было импортировать только требуемый модуль;
// в текущей реализации импортируются все 500kb библиотеки, что недопустимо
// для мобильной сборки, поэтому нужный виджет пришлось пока что просто скопировать сюда
@Component({
  selector: 'geo-layer-icon',
  templateUrl: 'layer-type-icon.widget.html',
  styleUrls: ['./layer-type-icon.widget.less']
})
export class LayerTypeIconWidget {
  @Input() type:string;

  geomTypeNames = {
    point: 'Точки',
    polygon: 'Полигоны',
    linestring: 'Линии',
    unknown: 'Тип геометрии не определен',
    geometry: 'Тип геометрии не определен',
    multipoint: 'Мультиточки',
    multilinestring: 'Мультилинии',
    multipolygon: 'Мультиполигоны',
    geometrycollection: 'Коллекция',
    mixed: 'Смешанная геометрия',
    raster: 'Растровое изображение',
    wms: 'Слой WMS',
    wfs: 'Слой WFS',
    wcs: 'Слой WCS',
    tms: 'Слой TMS',
    arcgis: 'Слой ArcGIS',
    wmts: 'Слой WMTS',
    geoserver: 'Слой Geoserver'
  };
}
