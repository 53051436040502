import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var Hammer:any;

@Directive({
  selector: '[swipe-listener]'
})
export class SwipeListener implements OnInit {
  @Input() swipeDir = 'all';

  @Output() onSwipeLeft = new EventEmitter();
  @Output() onSwipeRight = new EventEmitter();
  @Output() onSwipeUp = new EventEmitter();
  @Output() onSwipeDown = new EventEmitter();

  private direction:any = {
    all: Hammer.DIRECTION_ALL,
    vertical: Hammer.DIRECTION_VERTICAL,
    horizontal: Hammer.DIRECTION_HORIZONTAL
  };

  constructor(private el:ElementRef) {}

  ngOnInit() {
    const options = {};

    const mc = new Hammer(this.el.nativeElement, options);

    mc.get('pan').set({ direction: this.direction[this.swipeDir] });
    mc.get('swipe').set({ direction: this.direction[this.swipeDir] });

    mc.on('swipeleft', () => {
      console.log('<-');
      this.onSwipeLeft.emit({});
    });

    mc.on('swiperight', () => {
      console.log('->');
      this.onSwipeRight.emit({});
    });

    mc.on('swipeup', () => {
      console.log('^');
      this.onSwipeUp.emit({});
    });

    mc.on('swipedown', () => {
      console.log('_');
      this.onSwipeDown.emit({});
    });
  }
}
