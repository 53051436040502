import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { switchMap } from 'rxjs/operators';
import { IMapResult, IPluginInterface, ISearchResult } from 'shared/interfaces';
import { Feature, PluginClass, Utils } from '../../classes';
import { ConnectionService, StateService } from '../../services';
import { DocumentService } from 'shared/components/search_results/single_result/document.service';
import { SearchOption, SearchType } from '../shared';

@Component({
  selector: 'search-result',
  templateUrl: 'search-results.component.html',
  styleUrls: ['search-results.component.less'],
  providers: [DocumentService]
})
export class SearchResultsComponent extends PluginClass implements ISearchResult, OnInit {
  @ViewChild('pswp') pswp:ElementRef;

  expanded = false;
  active = false;
  features:Feature[];
  results:{ [K in SearchType]?: Feature[] } = {};
  resultFeature:Feature;
  offsetTop = '100%';
  params:SearchOption[] = [];
  paramsMap:{ [K in SearchType]: SearchOption };

  private mapResultPlugin:IMapResult;

  constructor(connectionService:ConnectionService, private stateService:StateService, private el:ElementRef) {
    super();
    connectionService.addPlugin((this.constructor as any).name, this);
  }

  ngOnInit() {
    this.stateService.resultFeature$.subscribe(feature => {
      if (!feature) {
        this.resultFeature = null;
        this.mapResultPlugin.clearResult();
        setTimeout(() => {
          this.getOffsetTop();
        });
      }
    });
    this.paramsMap = this.params
      // TODO: реализовать поиск по слоям в мобильной версии
      .filter(value => value.type !== 'layer')
      .reduce((acc:any, value:SearchOption) => ({ ...acc, ...{ [value.type]: value } }), {});
  }

  addInterface(name:string, pi:IPluginInterface) {
    switch (name) {
      case 'MapResults':
        this.mapResultPlugin = pi as IMapResult;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string) {
    switch (name) {
      case 'MapResults':
        this.mapResultPlugin = null;
        break;
    }
  }

  activate() {
    this.active = true;
    setTimeout(() => {
      this.getOffsetTop();
    });
  }

  deactivate() {
    this.active = false;
  }

  showFeatureInfo(feature:Feature) {
  }

  setFeatures(features:Feature[] = []) {
    // игнорируем поиск, пока идет работа с выбранным объектом
    if (this.resultFeature) {
      return;
    }
    if (features && features.length) {
      if (this.paramsMap.objects.active) {
        this.results.objects = features.filter(feature => feature.layer.name !== 'Адресный поиск');
      }
      if (this.paramsMap.address.active) {
        this.results.address = features.filter(feature => feature.layer.name === 'Адресный поиск');
      }
      this.features = features;
      this.activate();
    } else {
      this.deactivate();
    }
  }

  getFeatures():Feature[] {
    return;
  }

  updateFeatures(features:Feature[]) {}

  showInfo(feature:Feature) {
    if (feature.layer.id && feature.properties[feature.layer.pk] != null) {
      this.stateService
        .getImages(feature)
        .pipe(switchMap(images => Utils.getImagesMeta(images)))
        .subscribe(images => {
          feature.images = images;
          this.setFeature(feature);
        });
    } else {
      this.setFeature(feature);
    }
  }

  editInfo(feature:Feature) {}

  clearResults() {
    this.features = null;
    this.deactivate();
  }

  toggleResults() {
    this.expanded = !this.expanded;
    setTimeout(() => {
      this.getOffsetTop();
    });
  }

  expand() {
    this.expanded = true;
    setTimeout(() => {
      this.getOffsetTop();
    });
  }

  // на повторный свайп вниз закрываем поиск
  // если выбран объект, возвращаемся к списку
  collapse() {
    if (this.expanded) {
      this.expanded = false;
    } else if (this.resultFeature) {
      this.stateService.resultFeature$.next(null);
    } else {
      this.deactivate();
    }
    setTimeout(() => {
      this.getOffsetTop();
    });
  }

  getOffsetTop() {
    let header:HTMLElement;
    if (this.expanded) {
      this.offsetTop = '53px';
      return;
    } else if (this.resultFeature) {
      header = this.el.nativeElement.getElementsByClassName('feature-header')[0];
    } else {
      header = this.el.nativeElement.getElementsByClassName('count')[0];
    }
    this.offsetTop = `calc(100% - ${header ? header.offsetHeight : 0}px)`;
  }

  getNumeralEnding() {
    if (this.features.length > 10) {
      const lastTwoDigits = +this.features.length.toString().slice(-2);
      if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return 'ов';
      }
    }
    const lastDigit = +this.features.length.toString().slice(-1);
    if (lastDigit === 1) {
      return '';
    } else if (lastDigit >= 2 && lastDigit < 5) {
      return 'а';
    } else {
      return 'ов';
    }
  }

  getFeatureTitle():string {
    if (!this.resultFeature) {
      return;
    }
    const titleAttribute = this.resultFeature.layer.columns.find(column => column.inPopup);
    if (!titleAttribute) {
      return '—';
    }
    return this.resultFeature.properties[titleAttribute.name] || '—';
  }

  getKeys() {
    if (!this.paramsMap) {
      return [];
    }
    return Object.keys(this.paramsMap);
  }

  openGallery(idx:number) {
    const gallery = new PhotoSwipe(
      this.pswp.nativeElement,
      PhotoSwipeUI_Default,
      this.resultFeature.images.map(image => ({
        src: image.url as string,
        w: image.width,
        h: image.height
      })),
      {
        index: idx
      }
    );
    gallery.init();
  }

  private setFeature(feature:Feature) {
    this.resultFeature = feature;
    this.stateService.resultFeature$.next(feature);
    this.mapResultPlugin.showFeature([feature]);
    this.mapResultPlugin.goToFeature(feature);
    this.mapResultPlugin.setMarker(this.mapResultPlugin.getFeatureCenter(feature));
    this.toggleResults();
  }
}
