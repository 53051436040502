import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IMapResult, IMenu, IPluginInterface, IPosition, ISearch, ISearchResult, ITextSearch } from 'shared/interfaces';
import { PluginClass, Point, Utils } from '../../classes';
import { MapService } from '../../services';
import { Button } from '../shared';

@Component({
  selector: 'text-search',
  templateUrl: 'text-search.component.html',
  styleUrls: ['text-search.component.less']
})
export class TextSearchComponent extends PluginClass implements ITextSearch, OnInit {
  @ViewChild('input') queryInput:ElementRef;

  active = false;
  searchQuery = '';

  private menu:IMenu;
  private btn:Button = null;

  private searchPlugin:ISearch;
  private posPlugin:IPosition;
  private mapResultPlugin:IMapResult;
  private resultPlugin:ISearchResult;

  constructor(private mapService:MapService) {
    super();
    this.mapService.mapClick$.subscribe(() => this.cancel());
  }

  addInterface(name:string, pi:IPluginInterface) {
    switch (name) {
      case 'POS':
        this.posPlugin = pi as IPosition;
        break;
      case 'SearchEngine':
        this.searchPlugin = pi as ISearch;
        break;
      case 'ShowResult':
        this.resultPlugin = pi as ISearchResult;
        break;
      case 'MapResults':
        this.mapResultPlugin = pi as IMapResult;
        break;
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;

          this.buttonConfig.onClickMe = () => {
            this.activate();
          };

          this.buttonConfig.onDeactivate = () => {
            this.deactivate();
          };

          this.btn.setOptions(this.buttonConfig);
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'POS':
        this.posPlugin = null;
        break;
      case 'SearchEngine':
        this.searchPlugin = null;
        break;
      case 'ShowResult':
        this.resultPlugin = null;
        break;
      case 'MapResults':
        this.mapResultPlugin = null;
        break;
    }
  }

  activate() {
    this.active = true;
    // ставим фокус в инпут после того, как отработает анимация контейнера
    setTimeout(() => {
      this.queryInput.nativeElement.focus();
    }, 300);
  }

  deactivate() {
    this.active = false;
  }

  cancel() {
    this.searchQuery = '';
    this.clearResults();
    this.deactivate();
  }

  search() {
    const query = this.searchQuery.trim();

    if (!query) {
      return;
    }

    this.clearResults();

    const normalizedCoords = Utils.normalizeCoords(query);
    const dms = Utils.searchDMSCoords(normalizedCoords);
    const dd = Utils.searchDecimalCoords(normalizedCoords);

    if (dd || dms) {
      const point = dd ? new Point(Number(dd[1]), Number(dd[4])) : Utils.DMSCoordsToPoint(dms);
      this.goToPoint(point);
    } else {
      this.searchPlugin.searchText(query);
    }
  }

  clearResults() {
    this.resultPlugin.clearResults();
    this.mapResultPlugin.clearResult();
  }

  private goToPoint(point:Point) {
    this.posPlugin.goToPoint(point);
    this.mapResultPlugin.setMarker(point);
  }
}
