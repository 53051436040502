import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Attribute, Feature, Image } from '../../classes';

@Component({
  selector: 'crowdsource-form',
  templateUrl: './crowdsource-form.component.html',
  styleUrls: ['./crowdsource-form.component.less']
})
export class CrowdsourceFormComponent {
  @Input() feature:Feature;
  @Input() attributes:Attribute[] = [];
  @Input() imageFormats = ['png', 'jpeg', 'gif', 'jpg'];
  @Input() maxSize = 10485760; // = 10 Mb

  @Output() cancel = new EventEmitter();
  @Output() submit:EventEmitter<Feature> = new EventEmitter();

  @ViewChild('form') form:NgForm;

  onCancel() {
    this.cancel.emit();
  }

  onSubmit() {
    if (this.form.valid) {
      this.submit.emit(this.feature);
    }
  }

  addImage(files:File[]) {
    files.forEach(file => {
      const image = new Image({ title: file.name, file });
      this.readUrl(image);
      this.feature.images.push(image);
    });
  }

  removeImage(idx:number) {
    this.feature.images.splice(idx, 1);
  }

  private readUrl(image:Image) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      image.url = fileReader.result as string;
    };
    fileReader.readAsDataURL(image.file);
  }
}
