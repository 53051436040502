import { Component, ElementRef, OnInit } from '@angular/core';
import { PluginClass, Point, Utils } from '../../classes';
import { IMapResult, IMenu, IPluginInterface, IPosition } from 'shared/interfaces';
import { ConnectionService } from '../../services';
import { Button } from '../shared';

@Component({
  selector: 'location',
  template: ''
})
export class LocationComponent extends PluginClass implements OnInit {
  private positionPlugin:IPosition;
  private mapResultsPlugin:IMapResult;
  private menu:IMenu;
  private btn:Button = null;

  constructor(private connectionService:ConnectionService, private el:ElementRef) {
    super();
    // добавляем в массив плагинов
    this.connectionService.addPlugin((this.constructor as any).name, this);
  }

  ngOnInit() {
    Utils.removeElement(this.el.nativeElement as HTMLElement);
  }

  addInterface(name:string, pi:IPluginInterface) {
    switch (name) {
      case 'POS':
        this.positionPlugin = pi as IPosition;
        break;
      case 'MapResults':
        this.mapResultsPlugin = pi as IMapResult;
        break;
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.buttonConfig.onClickMe = () => {
            this.showUserLocation();
          };
          this.btn.setOptions(this.buttonConfig);
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string) {
    switch (name) {
      case 'POS':
        this.positionPlugin = null;
        break;
      case 'MapResults':
        this.mapResultsPlugin = null;
        break;
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  private showUserLocation() {
    navigator.geolocation.getCurrentPosition(position => {
      const coords = position.coords as Coordinates;
      const point = new Point(coords.longitude, coords.latitude);
      this.positionPlugin.goToPoint(point);
      this.mapResultsPlugin.setMarker(point);
    });
  }
}
